import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Dialog, Divider } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { TrackJS } from "trackjs";
import "intro.js/introjs.css";
import {
  causationStates,
  pdfState,
  //socketEvents,
  SUBSCRIPTION_TYPE,
  toastOptions,
} from "../../../constants";
import { CausationResponseModal } from "../CausationResponseModal";
import {
  ExpandCausationHeader,
  TableCausation,
  ExpandCausationCountGeneral,
  BannerCausation,
  PdfViewContainer,
  Form,
  Payments,
  PdfInformation,
  Observation,
  BottomContainer,
} from "./components";
import {
  INITIAL_PRODUCT,
  INITIAL_TAX,
  searchType,
  INITIAL_SUPPLIER,
  INITIAL_VALUES,
  INITIAL_ITEM_VALUES,
  INITIAL_COUNT_GENERAL,
  INITIAL_SEARCH,
  TAX_VALUES,
  MODAL_RESPONSE_INITIAL_STATE,
  MODAL_RESPONSE_OPEN_INITIAL_STATE,
  DEFAULT_ERROR_MESSAGE,
  INITIAL_PAYMENT_METHOD,
  ITEM_COLUMN_TYPE,
} from "./constants";

import * as CausationService from "../../../services/api/causation";
import * as InvoiceService from "../../../services/api/invoices";
import { fetchDianPDF } from "../../../services/fetch-dian-url";
import { useCausation, useChat, useSubscription } from "../../../hooks";
import { objectIsEmpty } from "../../../utils/objectUtils";
import {
  checkCompanyNews,
  fixNumber,
  getAmountByPercentage,
  getDateByLocalTimeZone,
  truncateDecimalsDown,
  truncateDecimalsUp,
} from "../../../utils";
import { Overlade, SubscriptionModal, TableSkeleton } from "../..";
import { CausationContext, PaymentsContext, UserContext } from "contexts";
import * as amplitude from "@amplitude/analytics-browser";
//import { connectSocket } from "socket";

export function ExpandCausationModal() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { createInvoice, updateInvoice, uploadInvoiceFile } =
    useContext(PaymentsContext);
  const {
    payments,
    paymentsAdvances,
    getItemAmountTotal,
    handleSetPaymentsAdvances,
    handleSetPayments,
    hasCompanyWareHouse,
    hasErpCausation,
  } = useCausation();
  const { handleSetOriginalItems } = useContext(CausationContext);
  const [invoice, setInvoice] = useState({});
  const [supplierFound, setSupplierFound] = useState(false);
  const [formValues, setFormValues] = useState({ ...INITIAL_VALUES });
  const [items, setItems] = useState([
    {
      ...INITIAL_ITEM_VALUES,
      unitValue: location?.state?.amount || 0,
      amountTotal: location?.state?.amount || 0,
    },
  ]);
  const [content, setContent] = useState(INITIAL_COUNT_GENERAL);
  const [search, setSearch] = useState(INITIAL_SEARCH);
  const [checked, setChecked] = useState(false);
  const [amountTotalNeto, setAmountTotalNeto] = useState(0);
  const [modalResponse, setModalResponse] = useState(
    MODAL_RESPONSE_INITIAL_STATE
  );
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [lastSyncCausationAt, setLastSyncCausationAt] = useState(null);
  const [requestInProcess, setRequestInProcess] = useState(false);

  const subscription = useSubscription({ type: SUBSCRIPTION_TYPE.CAUSATION });

  const isCollectionAccountCreation = location?.state?.createCollectionAccount;

  const { currentUser } = useContext(UserContext);

  useChat();

  //const socket = connectSocket();

  const startTime = useRef(Date.now());

  useEffect(() => {
    amplitude.track("CAUSATION_LOADED", {
      user_id: currentUser.id,
      company_id: currentUser.company_id,
      invoice_id: parseInt(id),
    });
    startTime.current = Date.now();
  }, [id]);

  const handleOnChange = (event) => {
    let { value, name } = event.target;

    if (name === "documentType") {
      setFormValues((prev) => ({
        ...prev,
        documentType: value,
        costCenter: !value.use_cost_center ? "" : prev.costCenter,
      }));
      return;
    }

    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnChangeAutoComplete = async (fromType, newValue) => {
    try {
      if (fromType === "supplier" && newValue) {
        const payments = await CausationService.search(
          searchType.paymentMethod,
          "",
          newValue?.account_id
        );
        setSearch((prev) => ({ ...prev, paymentMethod: payments }));
      }
      setFormValues((prev) => ({
        ...prev,
        [fromType]: newValue,
      }));
    } catch (error) {
      toast.error(
        "Ha ocurrido un error, no pudimos obtener los anticipos.",
        toastOptions
      );
    }
  };

  const addNewTableInfo = () => {
    setItems((prev) => [
      ...prev,
      {
        ...INITIAL_ITEM_VALUES,
        id: new Date().getTime(),
      },
    ]);
  };

  const handleDeleteItem = (idItem) => {
    const updateItem = items.filter((item) => item.id !== idItem);
    setItems(updateItem);
  };

  const handleOnChangeTableItem = useCallback((params, field, value = null) => {
    const indexItem = items.findIndex((item) => item.id === params.id);
    const updateItems = [...items];
    const itemToUpdate = updateItems[indexItem];

    const updateAmountTotal = (percentage) => {
      itemToUpdate.amountTotal = getItemAmountTotal(
        field,
        percentage,
        params.row
      );
    };

    switch (field) {
      case "taxIva":
      case "taxRetefuente":
        if (value !== null) {
          const taxIndex = search[field].findIndex((tax) => tax.id === value);
          itemToUpdate[field] = search[field][taxIndex];
          updateAmountTotal(search[field][taxIndex].percentage);
        } else {
          itemToUpdate[field] = { ...INITIAL_TAX };
          updateAmountTotal(0);
        }
        break;

      case "product":
        itemToUpdate[field] = value?.fcode ? value : INITIAL_PRODUCT;
        break;

      case "unitValue":
      case "discount":
      case "quantity":
        itemToUpdate[field] = value;
        updateAmountTotal(value);
        break;

      case "description":
      case "warehouse":
      case "costCenter":
        itemToUpdate[field] = value;
        break;

      default:
        itemToUpdate[field] = params.props.value;
    }

    setItems(updateItems);
  });

  const calcularContent = () => {
    let total = 0;
    let descuento = 0;
    let subtotal = 0;
    let iva = 0;
    let retefuente = 0;
    let reteIvaValue = 0;
    let reteIcaValue = 0;

    if (items.length <= 0) {
      setContent(INITIAL_COUNT_GENERAL);
    }

    for (const item of items) {
      let { unitValue, quantity, discount, taxIva, taxRetefuente } = item;
      discount = +discount;
      quantity = +quantity;

      const totalUnitValue = fixNumber(
        truncateDecimalsUp(quantity * unitValue)
      );
      const totalUnitWithDiscount = fixNumber(totalUnitValue - discount);

      total = fixNumber(total + totalUnitValue);
      descuento = fixNumber(descuento + discount);
      subtotal = fixNumber(total - descuento);

      iva = fixNumber(
        iva +
          truncateDecimalsUp(totalUnitWithDiscount * (taxIva.percentage / 100))
      );

      //Instalar una libreria para resolver esto de una forma mas limpia
      retefuente = fixNumber(
        retefuente +
          truncateDecimalsUp(
            totalUnitWithDiscount * (taxRetefuente.percentage / 100)
          )
      );
    }

    if (content.reteIca.id !== null) {
      reteIcaValue = getAmountByPercentage(
        subtotal,
        content.reteIca.percentage,
        1000
      );
    }

    if (content.reteIva.id !== null) {
      reteIvaValue = getAmountByPercentage(iva, content.reteIva.percentage);
    }

    setContent((prev) => {
      return {
        ...prev,
        totalGross: total,
        discount: descuento,
        subtotal: subtotal,
        ivaTotal: iva,
        retefuenteTotal: retefuente,
        reteIcaValue,
        reteIvaValue,
      };
    });
  };

  const calcularGeneralTotalNeto = () => {
    const { subtotal, ivaTotal, retefuenteTotal, reteIvaValue, reteIcaValue } =
      content;

    const result = truncateDecimalsDown(
      subtotal + ivaTotal - retefuenteTotal - reteIvaValue - reteIcaValue
    );

    setAmountTotalNeto(result);
  };

  const handleOnChangeGeneralTax = (e) => {
    const { name, value } = e.target;
    const { ivaTotal, subtotal } = content;

    if (name === "ReteIVA") {
      setContent((prev) => {
        return {
          ...prev,
          reteIva: value,
          reteIvaValue: getAmountByPercentage(ivaTotal, value.percentage),
        };
      });
    }

    if (name === "ReteICA") {
      setContent((prev) => {
        return {
          ...prev,
          reteIca: value,
          reteIcaValue: getAmountByPercentage(subtotal, value.percentage, 1000),
        };
      });
    }
  };

  const logValidationError = (errorType) => {
    amplitude.track("CAUSATION_VALIDATION_ERROR", {
      error_type: errorType,
      user_id: currentUser.id,
      company_id: currentUser.company_id,
      invoice_id: parseInt(id),
    });
  };

  const logBackendError = (errorType) => {
    amplitude.track("CAUSATION_BACKEND_ERROR", {
      error_type: errorType,
      user_id: currentUser.id,
      company_id: currentUser.company_id,
      invoice_id: parseInt(id),
    });
  };

  const handleSendCausation = async () => {
    if (
      formValues.documentType.id === null ||
      formValues.documentType.id === undefined
    ) {
      logValidationError("MISSING_DOCUMENT_TYPE");
      return toast.error("Seleccione el tipo de factura", toastOptions);
    }

    if (
      formValues.supplier?.id === null ||
      formValues.supplier?.id === undefined ||
      (formValues.supplier?.id && !supplierFound)
    ) {
      logValidationError("MISSING_SUPPLIER");
      return toast.error("Seleccione un proveedor", toastOptions);
    }

    if (formValues.nroComprobante === "") {
      logValidationError("MISSING_INVOICE_NUMBER");
      return toast.error("Ingrese el número de factura", toastOptions);
    }

    if (formValues.dateElaboration === null) {
      logValidationError("MISSING_ELABORATION_DATE");
      return toast.error("Seleccione la fechas de elaboración", toastOptions);
    }

    if (
      formValues.costCenter === "" &&
      formValues.documentType.use_cost_center
    ) {
      logValidationError("MISSING_COST_CENTER");
      return toast.error("Seleccione un centro de costo", toastOptions);
    }

    if (items.length <= 0) {
      logValidationError("NO_PRODUCTS");
      return toast.error("Seleccione un producto", toastOptions);
    }

    for (const item of items) {
      if (item.product.id === null || !item.product?.id) {
        logValidationError("INVALID_PRODUCT");
        return toast.error("Seleccione un producto", toastOptions);
      }

      if (item.quantity <= 0) {
        logValidationError("INVALID_QUANTITY");
        return toast.error("Ingrese una cantidad correcta", toastOptions);
      }

      if (item.unitValue < 0 || item.discount < 0) {
        logValidationError("INVALID_AMOUNT");
        return toast.error(
          "Valor unitario o descuento incorrecto",
          toastOptions
        );
      }

      if (item.amountTotal < 0) {
        logValidationError("NEGATIVE_TOTAL");
        return toast.error(
          "El monto total no puede ser negativo",
          toastOptions
        );
      }

      if (
        item.product?.is_inventory_control &&
        hasCompanyWareHouse &&
        !item.warehouse
      ) {
        logValidationError("MISSING_WAREHOUSE");
        return toast.error(
          "Seleccione una bodega para los productos",
          toastOptions
        );
      }

      if (isNaN(item.amountTotal)) {
        logValidationError("INVALID_TOTAL");
        TrackJS.console.debug(item);
        return toast.error(
          "Por favor, revise los datos ingresados",
          toastOptions
        );
      }
    }

    for (const payment of payments) {
      if (payment.accounting_concept === "") {
        logValidationError("MISSING_PAYMENT_METHOD");
        return toast.error("Seleccione un método de pago", toastOptions);
      }
      if (payment.amount <= 0) {
        logValidationError("INVALID_PAYMENT_AMOUNT");
        return toast.error(
          "Ingrese un monto correcto en los metodos de pago",
          toastOptions
        );
      }

      if (
        payment.data?.dueDate === null &&
        payment.data?.due_type === -1 &&
        !hasErpCausation
      ) {
        logValidationError("MISSING_PAYMENT_DATE");
        return toast.error("Seleccione una fecha de pago", toastOptions);
      }

      if (
        payment.accounting_concept === "Cruzar anticipo" &&
        !payment.data?.AcDueBalanceID
      ) {
        logValidationError("MISSING_ADVANCE_SELECTION");
        return toast.error("Seleccione un anticipo", toastOptions);
      }

      if (
        payment.accounting_concept === "Cruzar anticipo" &&
        payment.amount > payment.data?.Value
      ) {
        logValidationError("ADVANCE_AMOUNT_EXCEEDED");
        return toast.error(
          "El monto del anticipo no puede ser mayor al saldo del anticipo",
          toastOptions
        );
      }
    }

    const amountRegister = payments.reduce(
      (total, payment) => fixNumber(total + payment.amount),
      0
    );

    if (amountRegister !== amountTotalNeto) {
      logValidationError("PAYMENT_AMOUNT_MISMATCH");
      return toast.error(
        "El monto total de los pagos no coincide con el total neto",
        toastOptions
      );
    }
    setRequestInProcess(true);

    const action = "create";
    const body = createSaveCausationBody(action);
    const storedSupplier = await CausationService.storeSupplier({
      id: formValues.supplier.id,
    });

    let createdInvoice = {};

    if (!id) {
      const uploadedFile = await uploadInvoiceFile(selectedFiles);
      const date = new Date();
      createdInvoice = await createInvoice({
        invoice_number: formValues.nroComprobante,
        file_path: uploadedFile?.location,
        provider_id: storedSupplier.id,
        amount: amountTotalNeto,
        issue_date: date,
        type: "collection_account",
      });
    } else {
      await updateInvoice(id, {
        provider_id: storedSupplier.id,
      });
    }

    try {
      setModalResponse({
        ...MODAL_RESPONSE_OPEN_INITIAL_STATE,
        type: "loading",
      });
      const resp = await CausationService.sendCausation({
        ...body,
        invoiceID: createdInvoice?.id || id,
      });

      if (resp?.statusCode === 403) {
        setRequestInProcess(false);
        logBackendError(
          resp?.message === "causationService.causate.error"
            ? DEFAULT_ERROR_MESSAGE
            : resp?.message
        );
        return setModalResponse({
          type: "error",
          open: true,
          textError:
            resp?.message === "causationService.causate.error"
              ? DEFAULT_ERROR_MESSAGE
              : resp?.message,
        });
      }

      if (resp?.statusCode === 202) {
        setRequestInProcess(false);
        amplitude.track("CAUSATION_CREATED", {
          timeSpent: Date.now() - startTime.current,
          user_id: currentUser.id,
          invoice_id: parseInt(id),
          company_id: currentUser.company_id,
        });
        return setModalResponse({
          ...MODAL_RESPONSE_OPEN_INITIAL_STATE,
          type: "success",
          statusCode: resp?.statusCode,
        });
      }

      if (typeof resp === "number") {
        setRequestInProcess(false);
        amplitude.track("CAUSATION_CREATED", {
          timeSpent: Date.now() - startTime.current,
          user_id: currentUser.id,
          invoice_id: parseInt(id),
          company_id: currentUser.company_id,
        });
        return setModalResponse({
          ...MODAL_RESPONSE_OPEN_INITIAL_STATE,
          type: "success",
        });
      }
      setRequestInProcess(false);
      logBackendError(DEFAULT_ERROR_MESSAGE);
      return setModalResponse({
        type: "error",
        open: true,
        textError: DEFAULT_ERROR_MESSAGE,
      });
    } catch (error) {
      setRequestInProcess(false);
      logBackendError(DEFAULT_ERROR_MESSAGE);
      return setModalResponse({
        ...MODAL_RESPONSE_OPEN_INITIAL_STATE,
        type: "error",
      });
    }
  };

  const handleSaveCausation = async (markAsCaused = false, redirect = true) => {
    try {
      setRequestInProcess(true);
      const action = "save";
      const body = createSaveCausationBody(action);

      const storedSupplier = await CausationService.storeSupplier({
        id: formValues.supplier.id,
      });

      let createdInvoice = {};

      if (!id) {
        const uploadedFile = await uploadInvoiceFile(selectedFiles);
        const date = new Date();
        createdInvoice = await createInvoice({
          invoice_number: formValues.nroComprobante,
          file_path: uploadedFile?.location,
          provider_id: storedSupplier.id,
          amount: amountTotalNeto,
          issue_date: date,
          type: "collection_account",
        });
      } else {
        await updateInvoice(id, {
          provider_id: storedSupplier.id,
        });
      }

      const resp = await CausationService.saveCausation({
        ...body,
        invoiceID: createdInvoice?.id || id,
      });

      setRequestInProcess(false);
      amplitude.track("CAUSATION_SAVED", {
        user_id: currentUser.id,
        invoice_id: parseInt(id),
        company_id: currentUser.company_id,
      });
      if (!redirect) return;

      if (!objectIsEmpty(resp)) {
        navigate("/payments/invoices", { replace: true });
        return toast.success(
          markAsCaused
            ? "¡Factura marcada como causada con éxito!"
            : "¡Guardamos con la información con éxito!",
          toastOptions
        );
      } else {
        return toast.success("No pudimos guardar la información", toastOptions);
      }
    } catch (error) {
      setRequestInProcess(false);
      logBackendError(DEFAULT_ERROR_MESSAGE);
      toast.error(
        "Ha ocurrido un error, por favor intente más tarde.",
        toastOptions
      );
    }
  };

  const getSiigoSupplier = async (supplierCausation, invoice, supplier) => {
    if (supplierCausation) {
      return supplierCausation;
    }

    if (invoice && invoice.provider_document_number) {
      if (supplierFound) {
        const res = await CausationService.search(
          searchType.supplier,
          invoice.provider_document_number
        );
        const supplierOptions = supplier.push(res.at(0));
        setSearch((prev) => ({
          ...prev,
          supplier: supplierOptions,
        }));

        return res.at(0);
      } else {
        return {
          email: "",
          full_name: invoice.provider_name?.toUpperCase(),
          id: null,
          document_number: invoice.provider_document_number,
        };
      }
    }

    return INITIAL_SUPPLIER;
  };

  const createSaveCausationBody = (action) => {
    let products = [];
    for (const item of items) {
      products.push({
        productID: item.product.id ? +item.product.id : "",
        quantity: item.quantity,
        description: item.description,
        unitValue: item.unitValue,
        discount: item.discount,
        taxIvaID: +item.taxIva.id ? item.taxIva.id : "",
        taxRetefuenteID: +item.taxRetefuente.id ? item.taxRetefuente.id : "",
        amountTotal: item.amountTotal,
        warehouse: item?.warehouse || "",
        costCenter: item?.costCenter || "",
      });
    }

    let paymentsAdvances = [];
    let paymentsMethods = [];
    for (const payment of payments) {
      if (
        payment?.accounting_concept === "Cruzar anticipo" &&
        action === "create"
      ) {
        paymentsAdvances.push({
          dueName: payment?.data?.DueName,
          amount: +payment?.amount,
          ACAccountCode: payment?.data?.ACAccountCode,
          AcDueBalanceID: payment?.data?.AcDueBalanceID,
          DuePrefix: payment?.data?.DuePrefix,
          DueConsecutive: payment?.data?.DueConsecutive,
        });
      }

      if (payment?.accounting_concept !== "Cruzar anticipo") {
        paymentsMethods.push({
          paymentMethodID: +payment?.data?.id,
          dueDate: payment?.data?.dueDate
            ? format(new Date(payment?.data?.dueDate), "yyyy-MM-dd")
            : null,
          amount: +payment?.amount,
          dueType: payment?.data?.due_type,
          shareNumber: payment?.share_number,
        });
      }
    }

    const isHasPayments = paymentsMethods.length || paymentsAdvances.length;

    return {
      invoiceTypeID: +formValues?.documentType?.id || null,
      supplierID: +formValues?.supplier?.id || null,
      payments: isHasPayments
        ? {
            ...(paymentsMethods.length && { paymentMethods: paymentsMethods }),
            ...(paymentsAdvances.length && {
              purchaseAdvances: paymentsAdvances,
            }),
          }
        : null,
      invoiceType: {
        nroComprobante: formValues?.nroComprobante || null,
        costCenter: formValues?.costCenter?.id || 0,
        dateElaboration: formValues.dateElaboration
          ? format(new Date(formValues.dateElaboration), "yyyy-MM-dd")
          : null,
        dateExpiration: format(
          new Date(formValues.dateExpiration),
          "yyyy-MM-dd"
        ),
      },
      products: products,
      totales: {
        totalBruto: content.totalGross,
        discount: content.discount,
        subtotal: content.subtotal,
        ivaTotal: content.ivaTotal,
        retefuenteTotal: content.retefuenteTotal,
        reteIvaValue: content.reteIvaValue,
        reteIvaID: +content.reteIva?.id ? content.reteIva.id : "",
        reteIcaValue: content.reteIcaValue,
        reteIcaID: +content.reteIca?.id ? content.reteIca.id : "",
        amountTotalNeto,
      },
      filePath: invoice?.file_path,
      invoiceExpirationDate: invoice?.expiration_date,
      observation: formValues?.observation,
      ...(id && { invoiceID: +id }),
    };
  };

  const getSearchsAndUpdateForm = async () => {
    try {
      const [
        invoiceTypes,
        supplier,
        paymentMethods,
        products,
        costCenter,
        taxIvas,
        ivaTypeFour,
        taxRetefuentes,
        taxReteIcas,
        taxReteIvas,
        invoice,
        causation,
      ] = await fetchInitialData();

      if (invoice && invoice.provider_document_number) {
        const res = await CausationService.searchErpSupplier({
          documentNumber: invoice.provider_document_number,
        });
        setSupplierFound(res);
      }

      setInvoice(invoice);
      setChecked(!!invoice?.file_path);
      setSearch((prev) => ({
        ...prev,
        invoiceType: invoiceTypes,
        supplier: supplier,
        product: products,
        paymentMethod: paymentMethods,
        taxIva: [...taxIvas, ...ivaTypeFour],
        taxRetefuente: taxRetefuentes,
        taxReteIca: taxReteIcas,
        taxReteIva: taxReteIvas,
        costCenter: costCenter,
      }));

      handleInvoiceState(invoice);

      if (id) {
        await handleCausationData(
          causation,
          invoice,
          supplier,
          invoiceTypes,
          paymentMethods,
          taxReteIcas,
          taxReteIvas
        );
      }

      if (causation?.products) {
        const items = mapProducts(
          causation.products,
          products,
          taxIvas,
          taxRetefuentes
        );
        handleSetOriginalItems(items);
        setItems(items);
      }
    } catch (error) {
      toast.warn(
        "No hemos podido leer automáticamente el PDF de la DIAN, por favor completa tus datos manualmente",
        toastOptions
      );
    } finally {
      setIsLoading(false);
    }
  };

  const uploadForm = async () => {
    const causation = await CausationService.getCausationShow(id);
    const invoice = await InvoiceService.GetInvoice({ id });
    if (invoice?.pdf_state === pdfState.in_process) return;

    const { issueDate, expirationDate, invoiceNumber } = causation;

    setInvoice(invoice);
    setFormValues((prev) => ({
      ...prev,
      nroComprobante:
        location?.state?.invoiceNumber || invoiceNumber || prev.nroComprobante,
      dateElaboration: location?.state?.issueDate
        ? location?.state?.issueDate
        : issueDate
        ? getDateByLocalTimeZone(issueDate)
        : null,
      dueDate: getDueDate(expirationDate, invoice),
    }));

    if (causation?.products) {
      const items = mapProducts(
        causation.products,
        search.product,
        search.taxIva,
        []
      );
      handleSetOriginalItems(items);
      setItems(items);
    }

    setActivateOverlade(false);
  };

  const fetchInitialData = async () => {
    return await Promise.all([
      CausationService.search(searchType.invoiceType),
      CausationService.search(searchType.supplier),
      CausationService.search(searchType.paymentMethod),
      CausationService.search(searchType.product),
      CausationService.search(searchType.costCenter),
      CausationService.search(searchType.tax, TAX_VALUES.iva),
      CausationService.search(searchType.tax, TAX_VALUES.ivaTypeFour),
      CausationService.search(searchType.tax, TAX_VALUES.retefuente),
      CausationService.search(searchType.tax, TAX_VALUES.reteIca),
      CausationService.search(searchType.tax, TAX_VALUES.reteIva),
      id && InvoiceService.GetInvoice({ id }),
      id &&
        !isCollectionAccountCreation &&
        CausationService.getCausationShow(id),
    ]);
  };

  const handleInvoiceState = (invoice) => {
    if (invoice?.origin === "dian" && invoice?.type === "invoice") {
      setActivateOverlade(invoice?.pdf_state === pdfState.in_process);
    }
  };

  const handleCausationData = async (
    causation,
    invoice,
    supplier,
    invoiceTypes,
    paymentMethods,
    taxReteIcas,
    taxReteIvas
  ) => {
    const {
      issueDate,
      expirationDate,
      invoiceNumber,
      supplier: supplierCausation,
      observation,
    } = causation;

    if (supplierCausation && supplierCausation?.account_id) {
      const payments = await CausationService.search(
        searchType.paymentMethod,
        "",
        supplierCausation?.account_id
      );
      setSearch((prev) => ({ ...prev, paymentMethod: payments }));
    }

    const siigoSupplier = await getSiigoSupplier(
      supplierCausation,
      invoice,
      supplier
    );

    const { invoiceType, paymentMethod, taxReteIca, taxReteIva } =
      getFormValuesSave(causation, {
        invoiceTypes,
        paymentMethods,
        taxReteIcas,
        taxReteIvas,
      });

    setFormValues((prev) => ({
      ...prev,
      documentType: invoiceType
        ? invoiceType
        : invoiceTypes.length === 1
        ? invoiceTypes[0]
        : prev.documentType,
      nroComprobante:
        location?.state?.invoiceNumber || invoiceNumber || prev.nroComprobante,
      dateElaboration: location?.state?.issueDate
        ? location?.state?.issueDate
        : issueDate
        ? getDateByLocalTimeZone(issueDate)
        : null,
      dueDate: getDueDate(expirationDate, invoice),
      supplier: siigoSupplier,
      paymentType: paymentMethod ? paymentMethod : INITIAL_PAYMENT_METHOD,
      observation,
    }));

    handleSetPayments(
      paymentMethod.length > 0 ? paymentMethod : [{ ...INITIAL_PAYMENT_METHOD }]
    );

    setContent((prev) => ({
      ...prev,
      reteIva: taxReteIva ? taxReteIva : prev.reteIva,
      reteIca: taxReteIca ? taxReteIca : prev.reteIca,
    }));
  };

  const mapProducts = (
    products,
    searchProducts,
    searchTaxIvas,
    searchTaxRetefuentes
  ) => {
    return products.map((product) => {
      const findProduct = (p) => p?.id === product.product?.id;

      const productsFind = getValuesFindSearch({
        searchType: "products",
        causationSearchSave: product?.product,
        callbackfind: findProduct,
        arrayToFind: searchProducts,
      });

      const findTaxIva = (taxIva) => taxIva.id === product?.taxIva.id;
      const taxIva = getValuesFindSearch({
        searchType: "taxIva",
        causationSearchSave: product?.taxIva.id,
        callbackfind: findTaxIva,
        arrayToFind: searchTaxIvas,
      });

      const findTaxReteFuente = (taxRetefuente) =>
        taxRetefuente.id === product?.taxRetefuente?.id;
      const taxRetefuente = getValuesFindSearch({
        searchType: "taxRetefuente",
        causationSearchSave: product?.taxRetefuente,
        callbackfind: findTaxReteFuente,
        arrayToFind: searchTaxRetefuentes,
      });

      const item = {
        id: Math.floor(Math.random() * 900000) + 100000,
        type: null,
        product: productsFind ? productsFind : { ...INITIAL_PRODUCT },
        description: product?.description || null,
        quantity: product?.quantity || 1,
        unitValue: product?.unitValue || 0,
        discount: product?.discount || 0,
        taxIva: !objectIsEmpty(taxIva) ? taxIva : { ...INITIAL_TAX },
        taxRetefuente: !objectIsEmpty(taxRetefuente)
          ? taxRetefuente
          : { ...INITIAL_TAX },
        isSelected: false,
        warehouse: product?.warehouse || null,
        taxIvaPercentage: product?.taxIvaPercentage || 0,
      };

      item.amountTotal = getItemAmountTotal("", 0, item);

      return item;
    });
  };

  const getDueDate = (expirationDate, invoice) => {
    if (expirationDate) {
      return getDateByLocalTimeZone(expirationDate);
    }

    if (invoice?.expiration_date) {
      return getDateByLocalTimeZone(invoice?.expiration_date);
    }

    return null;
  };

  const getFormValuesSave = (
    causation,
    { invoiceTypes, taxReteIcas, taxReteIvas }
  ) => {
    const findInvoiceType = (invoiceType) =>
      invoiceType?.id === causation?.invoiceType?.id;
    const invoiceType = getValuesFindSearch({
      searchType: "invoiceType",
      causationSearchSave: causation?.invoiceType,
      callbackfind: findInvoiceType,
      arrayToFind: invoiceTypes,
    });

    const paymentMethod = [];
    if (causation?.payments?.paymentMethods) {
      causation.payments.paymentMethods.forEach((payment) => {
        paymentMethod.push({
          accounting_concept: payment.accounting_concept,
          amount: +payment.amount,
          data: {
            dueDate: payment?.dueDate
              ? getDateByLocalTimeZone(payment.dueDate)
              : null,
            due_type: payment.due_type,
            id: payment.id,
          },
          name: payment.name,
        });
      });
    }

    if (causation?.payments?.purchaseAdvances) {
      causation.payments.purchaseAdvances.forEach((payment) => {
        paymentMethod.push({
          accounting_concept: "Cruzar anticipo",
          amount: +payment.amount,
          data: {
            DueName: payment.dueName,
            ACAccountCode: payment.ACAccountCode,
            AcDueBalanceID: payment.AcDueBalanceID,
            DuePrefix: payment.DuePrefix,
            DueConsecutive: payment.DueConsecutive,
            isFromSave: true,
          },
          name: payment.dueName,
        });
      });
    }

    const findTaxReteIca = (taxReteIca) =>
      taxReteIca.id === causation?.reteIca?.id;
    const taxReteIca = getValuesFindSearch({
      searchType: "taxReteIca",
      causationSearchSave: causation?.reteIca,
      callbackfind: findTaxReteIca,
      arrayToFind: taxReteIcas,
    });

    const findReteIva = (taxReteIva) =>
      taxReteIva.id === causation?.reteIva?.id;
    const taxReteIva = getValuesFindSearch({
      searchType: "taxReteIva",
      causationSearchSave: causation?.reteIva,
      callbackfind: findReteIva,
      arrayToFind: taxReteIvas,
    });

    return {
      invoiceType,
      paymentMethod,
      taxReteIca,
      taxReteIva,
    };
  };

  const getValuesFindSearch = ({
    searchType,
    causationSearchSave,
    callbackfind,
    arrayToFind = null,
  }) => {
    let findSearch;
    let arraySearch = [];

    if (Array.isArray(arrayToFind)) {
      arraySearch = arrayToFind;
    } else {
      arraySearch = search[searchType];
    }

    findSearch = arraySearch.find(callbackfind);

    if (!findSearch && causationSearchSave) {
      findSearch = causationSearchSave;
      setSearch((prev) => ({
        ...prev,
        [searchType]: [...arraySearch, findSearch],
      }));
    }

    return findSearch;
  };

  const getSearchOptions = async (type, query = "") => {
    if (query.length >= 3 || query.length === 0) {
      const resp = await CausationService.search(searchType[type], query);
      setSearch((prev) => ({
        ...prev,
        [type]: resp,
      }));
    }
  };

  const filePathIsFromDian = invoice?.file_path?.includes(
    "https://catalogo-vpfe.dian.gov.co/document"
  );

  useEffect(() => {
    if (filePathIsFromDian && id) {
      fetchDianPDF(id).then((response) => {
        response &&
          setInvoice({
            ...invoice,
            file_path: response.url,
            expiration_date: response.expiration_date,
          });
        setFormValues((prev) => ({
          ...prev,
          dueDate:
            !prev.dueDate && response?.expiration_date
              ? getDateByLocalTimeZone(response.expiration_date)
              : null,
        }));
      });
    }
  }, [invoice?.file_path]);

  useEffect(() => {
    getSearchsAndUpdateForm();
    CausationService.checkSiigoCredential().then((res) => {
      setLastSyncCausationAt(res.syncValidation.lastSyncCausationAt);
    });
  }, [id]);

  useEffect(() => {
    calcularContent();
  }, [items]);

  useEffect(() => {
    calcularGeneralTotalNeto();
  }, [content]);

  useEffect(() => {
    if (invoice?.pdf_state === pdfState.in_process && activateOverlade) {
      //todo: implementar socket cuando este la infraestructura
      //socket.emit(socketEvents.SUBSCRIBE_CAUSATION_PDF, { id });

      const newsInterval = setInterval(() => {
        checkCompanyNews()
          .then((updated) => {
            if (updated) {
              uploadForm();
              clearInterval(newsInterval);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }, 10000);
      return () => clearInterval(newsInterval);
    }
  }, [invoice]);

  // useEffect(() => {
  //   if (invoice?.pdf_state === pdfState.in_process) {
  //     socket.on(socketEvents.CAUSATION_PDF_DONE, () => uploadForm);

  //     return () => {
  //       socket.off(socketEvents.CAUSATION_PDF_DONE, getSearchsAndUpdateForm);
  //     };
  //   }
  // }, []);

  const isCaused = invoice?.causation_state === causationStates.caused;

  const [activateOverlade, setActivateOverlade] = useState(false);

  const selectItem = (id) => {
    const updateItems = [...items];
    const indexItem = items.findIndex((i) => i.id === id);
    updateItems[indexItem].isSelected = !updateItems[indexItem].isSelected;
    setItems(updateItems);
  };

  const handleTaxChange = (item, field, value) => {
    const taxValue =
      value !== null
        ? search[field].find((tax) => tax.id === value)
        : INITIAL_TAX;
    item[field] = taxValue;
    item.amountTotal = getItemAmountTotal(
      field,
      taxValue?.percentage || 0,
      item
    );
  };

  const handleChangeMultipleSelect = (field, value) => {
    try {
      const updateItems = items.map((item) => {
        if (!item.isSelected) return item;

        const handlers = {
          [ITEM_COLUMN_TYPE.product]: () => {
            item[field] = value?.fcode ? value : INITIAL_PRODUCT;
          },
          [ITEM_COLUMN_TYPE.taxIva]: () => handleTaxChange(item, field, value),
          [ITEM_COLUMN_TYPE.taxRetefuente]: () =>
            handleTaxChange(item, field, value),
          [ITEM_COLUMN_TYPE.warehouse]: () => {
            item[field] = item.product.is_inventory_control ? value : null;
          },
          [ITEM_COLUMN_TYPE.costCenter]: () => {
            item[field] = value ? value : null;
          },
        };

        if (handlers[field]) {
          handlers[field]();
        }

        return item;
      });

      setItems(updateItems);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectAllItems = (e) => {
    const updateItems = items.map((item) => {
      return { ...item, isSelected: e.target.checked };
    });
    setItems(updateItems);
  };
  return (
    <>
      {subscription?.showModalSubscription && (
        <SubscriptionModal subscription={subscription} />
      )}
      <CausationResponseModal
        open={modalResponse.open}
        type={modalResponse.type}
        text={modalResponse.textError}
        statusCode={modalResponse.statusCode}
        onClose={() => {
          setModalResponse(MODAL_RESPONSE_INITIAL_STATE);
        }}
        id={id}
      />
      <Dialog
        fullScreen
        open={true}
        style={{
          zIndex: 1000,
        }}
        disableEnforceFocus
      >
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <>
            <ExpandCausationHeader
              handleSendCausation={handleSendCausation}
              handleSaveCausation={handleSaveCausation}
              stateCausation={invoice?.causation_state}
              id={id}
              requestInProcess={requestInProcess}
              subscription={subscription}
            />
            {invoice?.causation_state === causationStates.caused && (
              <BannerCausation />
            )}
            <PdfInformation
              handleSaveCausation={handleSaveCausation}
              getValuesFindSearch={getValuesFindSearch}
              invoice={invoice}
              handleSetRequestInProcess={setRequestInProcess}
              handleSetActivateOverlade={setActivateOverlade}
              handleSetItems={setItems}
              getItemAmountTotal={getItemAmountTotal}
              taxIvas={search.taxIva}
            />
            <PdfViewContainer
              filePath={invoice?.file_path}
              checked={checked}
              filePathIsFromDian={filePathIsFromDian}
            >
              <Form
                formValues={formValues}
                search={search}
                checked={checked}
                handleOnChange={handleOnChange}
                handleOnChangeAutoComplete={handleOnChangeAutoComplete}
                getSearchOptionsSupplier={getSearchOptions}
                setFormValues={setFormValues}
                setChecked={setChecked}
                isCaused={isCaused}
                hasFilePath={!!invoice?.file_path}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                id={id}
                state={location?.state}
                lastSyncCausationAt={lastSyncCausationAt}
                supplierFound={supplierFound}
                setSupplierFound={setSupplierFound}
              />
            </PdfViewContainer>
            <Divider
              style={{
                width: "95",
                border: 0,
                height: "5px",
                backgroundColor: "#D0D5DD",
                margin: "35px auto",
              }}
            />
            <Overlade show={activateOverlade}>
              <TableCausation
                hasCompanyWareHouse={hasCompanyWareHouse}
                activateOverlade={activateOverlade}
                handleChangeMultipleSelect={handleChangeMultipleSelect}
                selectItem={selectItem}
                data={items}
                search={search}
                products={search.product}
                taxesIva={search.taxIva}
                taxesRete={search.taxRetefuente}
                handleOnChangeItem={handleOnChangeTableItem}
                addNewTableInfo={addNewTableInfo}
                handleDeleteItem={handleDeleteItem}
                getSearchOptionsProducts={getSearchOptions}
                isCaused={isCaused}
                handleSelectAllItems={handleSelectAllItems}
                getSearchOptionsSupplier={getSearchOptions}
                handleSetItems={setItems}
              />
              <BottomContainer
                payments={
                  <Payments
                    paymentsSearch={search.paymentMethod}
                    paymentsSelected={payments}
                    amountNeto={amountTotalNeto}
                    paymentsAdvances={paymentsAdvances}
                    handleSetPaymentsAdvances={handleSetPaymentsAdvances}
                    handleSetPayments={handleSetPayments}
                    dueDate={formValues.dueDate}
                    isCaused={isCaused}
                  />
                }
                observation={
                  <Observation
                    handleOnChange={handleOnChange}
                    formValues={formValues}
                    isCaused={isCaused}
                  />
                }
                expandCausationCountGeneral={
                  <ExpandCausationCountGeneral
                    allowReteIva={formValues.documentType?.allow_ret_vat}
                    allowReteIca={formValues.documentType?.allow_ret_ica}
                    content={content}
                    handleOnChangeGeneralTax={handleOnChangeGeneralTax}
                    taxReteIva={search.taxReteIva}
                    taxReteIca={search.taxReteIca}
                    amountTotalNeto={amountTotalNeto}
                    isCaused={isCaused}
                    state={location?.state}
                  />
                }
              />
            </Overlade>
          </>
        )}
      </Dialog>
    </>
  );
}
