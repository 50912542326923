import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { ExitIcon, RefreshOneIcon } from "assets";
import { BannerInformation } from "commons/components";
import * as CausationService from "../../../../services/api/causation";
import { INITIAL_PRODUCT, INITIAL_TAX } from "../constants";
import { BannerText } from "./BannerText";
import { pdfState, toastOptions } from "constants";
import { originInvoice, typeInvoice } from "constants";
import { objectIsEmpty } from "utils/objectUtils";
import * as amplitude from "@amplitude/analytics-browser";
import { UserContext } from "contexts";

const AMPLITUDE_EVENTS = {
  [pdfState.in_process]: {
    event: "CAUSATION_READING_PDF",
    getPayload: (currentUser, invoice) => ({
      user_id: currentUser.id,
      company_id: currentUser.company_id,
      invoice_id: parseInt(invoice?.id),
    }),
  },
  [pdfState.done]: {
    event: "CAUSATION_READING_PDF_SUCCESS",
    getPayload: (currentUser, invoice) => ({
      user_id: currentUser.id,
      company_id: currentUser.company_id,
      invoice_id: parseInt(invoice?.id),
    }),
  },
  [pdfState.failed]: {
    event: "CAUSATION_READING_PDF_FAILED",
    getPayload: (currentUser, invoice) => ({
      user_id: currentUser.id,
      company_id: currentUser.company_id,
      invoice_id: parseInt(invoice?.id),
    }),
  },
};

export const PdfInformation = ({
  invoice,
  handleSetRequestInProcess,
  handleSetActivateOverlade,
  handleSetItems,
  getItemAmountTotal,
  getValuesFindSearch,
  taxIvas,
  handleSaveCausation,
}) => {
  const { currentUser } = useContext(UserContext);

  const trackAmplitudeEvent = (eventKey) => {
    const eventConfig = AMPLITUDE_EVENTS[eventKey];
    if (eventConfig) {
      amplitude.track(
        eventConfig.event,
        eventConfig.getPayload(currentUser, invoice)
      );
    }
  };

  const [stepBannerInfo, setStepBannerInfo] = useState(invoice?.pdf_state);

  const readPdf = async () => {
    try {
      handleSetRequestInProcess(true);
      setStepBannerInfo(pdfState.in_process);
      handleSetActivateOverlade(true);

      trackAmplitudeEvent(pdfState.in_process);

      const products = await CausationService.readPdf(invoice?.id);
      if (!products?.length) {
        setStepBannerInfo(pdfState.failed);
        trackAmplitudeEvent(pdfState.failed);
        return;
      }

      const items = [];
      products.forEach((product) => {
        const taxIva = getValuesFindSearch({
          searchType: "taxIva",
          causationSearchSave: product?.taxIvaID,
          callbackfind: (taxIva) => taxIva.id === product?.taxIvaID,
          arrayToFind: taxIvas,
        });

        const formatItem = {
          id: crypto.randomUUID(),
          type: null,
          product: { ...INITIAL_PRODUCT },
          description: product?.description || null,
          quantity: product?.quantity || 1,
          unitValue: product?.unitValue || 0,
          discount: product?.discount || 0,
          taxIva: !objectIsEmpty(taxIva) ? taxIva : { ...INITIAL_TAX },
          taxRetefuente: { ...INITIAL_TAX },
          isSelected: false,
        };
        formatItem.amountTotal = getItemAmountTotal("", 0, formatItem);

        return items.push(formatItem);
      });

      handleSetItems(items);
      setStepBannerInfo(pdfState.done);
      toast.success("PDF leído con éxito", toastOptions);

      trackAmplitudeEvent(pdfState.done);
    } catch (error) {
      setStepBannerInfo(pdfState.failed);
      trackAmplitudeEvent(pdfState.failed);

      toast.error(
        "No hemos podido leer el PDF de la DIAN, por favor completa tus datos manualmente",
        toastOptions
      );
    } finally {
      handleSetActivateOverlade(false);
      handleSetRequestInProcess(false);
    }
  };

  useEffect(() => {
    setStepBannerInfo(invoice?.pdf_state);
    trackAmplitudeEvent(invoice?.pdf_state);
  }, [invoice]);

  const showBannerInformationPdfState =
    stepBannerInfo !== pdfState.done &&
    invoice?.origin === originInvoice.dian &&
    invoice?.type === typeInvoice.invoice;

  const stepsBannerInformation = {
    in_process: {
      text: null,
      endButton: (
        <p
          style={{
            color: "#5925DC",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            marginBottom: 0,
            cursor: "pointer",
          }}
          onClick={() => {
            handleSaveCausation(false, false);
            setStepBannerInfo("cancel");
            handleSetActivateOverlade(false);
          }}
        >
          Cancelar lectura y completar manual
        </p>
      ),
      startIcon: <Spinner animation="border" style={{ color: "#5925DC" }} />,
      customText: (
        <BannerText
          firstText={"Estamos leyendo la factura de la DIAN."}
          secondText={
            "Este proceso puede demorar, vuelva a ingresar en unos minutos."
          }
        />
      ),
    },
    cancel: {
      text: null,
      endButton: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <p
            style={{
              color: "#5925DC",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              marginBottom: 0,
              cursor: "pointer",
            }}
            onClick={() => readPdf((value) => setStepBannerInfo(value))}
          >
            Reintentar lectura
          </p>
          <RefreshOneIcon height="20" width="20" strokeColor={"#5925DC"} />
        </div>
      ),
      startIcon: null,
      customText: (
        <BannerText
          firstText={"Cancelaste la lectura de la factura."}
          secondText={"Completa manualmente la información o reinténtalo."}
        />
      ),
    },
    failed: {
      text: null,
      endButton: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <p
            style={{
              color: "#5925DC",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "20px",
              marginBottom: 0,
              cursor: "pointer",
            }}
            onClick={() => {
              readPdf((value) => setStepBannerInfo(value));
            }}
          >
            Reintentar lectura
          </p>
          <RefreshOneIcon height="20" width="20" strokeColor={"#5925DC"} />
        </div>
      ),
      startIcon: <ExitIcon height={38} width={38} />,
      customText: (
        <BannerText
          firstText={"No pudimos leer la factura."}
          secondText={"Reintenta nuevamente."}
        />
      ),
    },
  };

  return (
    showBannerInformationPdfState && (
      <BannerInformation
        startIcon={stepsBannerInformation[stepBannerInfo]?.startIcon}
        text={stepsBannerInformation[stepBannerInfo]?.text}
        endButton={stepsBannerInformation[stepBannerInfo]?.endButton}
        customText={stepsBannerInformation[stepBannerInfo]?.customText}
      />
    )
  );
};
